/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import testimonialsArr from '../helpers/testimonials';

import aboutBg from '../static/images/about-bg-min.jpg';
import mobDevImg from '../static/images/mob-dev.png';
import psdHtmlImg from '../static/images/psd-html.png';
import webDevImg from '../static/images/web-dev.png';
import wordpressImg from '../static/images/wordpress.png';

class About extends Component {
    state = {
        testimonials: testimonialsArr,
        testimonialToShow: 1
    };

    componentDidMount() {
        this.setState({
            interval: setInterval(() => {
                const { testimonial } = this.refs;
                testimonial.classList.add('hiding');

                setTimeout(() => {
                    this.setState({
                        testimonialToShow: Math.floor(
                            Math.random() * testimonialsArr.length
                        )
                    });

                    testimonial.classList.remove('hiding');
                }, 500);
            }, 10000)
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() {
        const { testimonials, testimonialToShow } = this.state;

        return (
            <Layout>
                <header
                    className="row about-header"
                    style={{ backgroundImage: `url(${aboutBg})` }}
                >
                    <section className="col-sm-6 col-sm-offset-3 col-xs text-center">
                        <h6>Florin Pop</h6>
                        <h1>Front-end developer</h1>
                        <p>
                            “Choose a job you love, and you will never have to
                            work a day in your life.”
                            <br />― Confucius
                        </p>
                    </section>
                </header>
                <div className="center-container">
                    <main className="row middle-xs about-container">
                        <div className="col-md-3 col-xs-12 about-left">
                            <h6>how it all started</h6>
                            <h2>The Story Behind and the Process</h2>
                        </div>
                        <div className="col-md-9 col-xs-12 about-right">
                            <p>
                                It all started in 2013 when a good friend of
                                mine introduced me to web development. We
                                started learning HTML, and quickly after I got
                                into CSS, at that point I was mind blown by it's
                                power!
                                <br /> <br />A few months later, I created a{' '}
                                <a
                                    className="text-red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://freelancer.com/u/Flopet17"
                                >
                                    Freelancer
                                </a>{' '}
                                account and started bidding on different jobs
                                there. Some of the clients liked my work so I
                                got myself few long-time gigs.
                                <br /> <br />
                                I started learning jQuery to enhance my "web
                                powers", but after a while, realizing that
                                jQuery is built on top of JavaScript, I dropped
                                it and started learning Vanilla JS, and when I
                                felt that I understood what is going on under
                                the hood, I picked ReactJS to help me on the
                                long run on the front-end. I also added NodeJS
                                (back-end) to my skills palette so I could
                                provide Full-Stack JavaScript services. :)
                                <br /> <br />I later switched to{' '}
                                <a
                                    className="text-red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://upwork.com/fl/florinpop"
                                >
                                    Upwork
                                </a>{' '}
                                as they seem to care more about their
                                freelancers.
                                <br /> <br />I also love creating tutorials on
                                various topics ranging from: how to create
                                different Web Components or Web Applications to
                                how to solve Coding Challenges (mostly in
                                JavaScript). You can find out more about these
                                on my{' '}
                                <Link className="text-red" to="/blog">
                                    blog
                                </Link>
                                .
                            </p>
                        </div>
                        <div className="col-md-3 col-xs-12 about-left">
                            <h6>Providing various services</h6>
                            <h2>Improving the web since 2013</h2>
                        </div>
                        <div className="col-md-9 col-xs-12 about-right">
                            <p>
                                As mentioned above, I'm specialized in HTML/CSS
                                and JavaScript on the frontend, but I'm pretty
                                comfortable with ReactJS, NodeJS, MongoDB,
                                Firebase, Wordpress, PHP, etc. For more details
                                about my completed projects check out my{' '}
                                {/* <Link to="/portfolio">
                                    <a className="text-red">portfolio</a>
                                </Link> */}
                                <a
                                    className="text-red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://codepen.io/FlorinPop17/"
                                >
                                    CodePen
                                </a>
                                ,&nbsp;
                                <a
                                    className="text-red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://github.com/florinpop17"
                                >
                                    Github
                                </a>
                                ,&nbsp;
                                <a
                                    className="text-red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://upwork.com/fl/florinpop"
                                >
                                    Upwork
                                </a>{' '}
                                and&nbsp;
                                <a
                                    className="text-red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://freelancer.com/u/Flopet17"
                                >
                                    Freelancer
                                </a>{' '}
                                profiles.
                                <br /> <br />
                                See the{' '}
                                <Link className="text-red" to="/timeline">
                                    Timeline
                                </Link>{' '}
                                for an overview of my progress and
                                accomplishments so far.
                                <br /> <br />
                                <Link className="text-red" to="/contact">
                                    Get in touch
                                </Link>{' '}
                                and we'll bring your website to the next level!
                            </p>
                        </div>
                        <div className="col-md-3 col-xs-6">
                            <div className="service text-center">
                                <img src={webDevImg} alt="" />
                                <h4>Web development</h4>
                                <small>
                                    HTML, CSS (Bootstrap, Foundation),
                                    JavaScript (React, Node, Express, MongoDB) ?
                                    You name it. I have it all covered!
                                </small>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-6">
                            <div className="service text-center">
                                <img src={mobDevImg} alt="" />
                                <h4>Responsive Design</h4>
                                <small>
                                    In {new Date().getFullYear()} you can't go
                                    online without having a mobile responsive
                                    website. Let me take care of that for you!
                                </small>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-6">
                            <div className="service text-center">
                                <img src={wordpressImg} alt="" />
                                <h4>Wordpress</h4>
                                <small>
                                    Millions of website are built on top of
                                    Wordpress, yours can become the next one!
                                </small>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-6">
                            <div className="service text-center">
                                <img src={psdHtmlImg} alt="" />
                                <h4>PSD to HTML</h4>
                                <small>
                                    Do you have a nice design in photoshop or
                                    even sketch?{' '}
                                    <Link className="text-red" to="/contact">
                                        Contact
                                    </Link>{' '}
                                    me and I'll convert them into fully
                                    responsive, nice looking websites!
                                </small>
                            </div>
                        </div>
                        <div className="col-md-3 col-xs-12 about-left">
                            <h6>Don't trust me?</h6>
                            <h2>See what others say</h2>
                            <div className="progress-bar" />
                        </div>
                        <div className="col-md-9 col-xs-12 about-right">
                            <div
                                ref="testimonial"
                                className="testimonial-container"
                            >
                                <p className="quote">
                                    <i className="fa fa-quote-right" />
                                    {testimonials[testimonialToShow].message}
                                </p>
                                <p className="text-right">
                                    - {testimonials[testimonialToShow].client},{' '}
                                    <a
                                        className="text-red"
                                        href={
                                            testimonials[testimonialToShow].link
                                        }
                                    >
                                        {
                                            testimonials[testimonialToShow]
                                                .platform
                                        }
                                    </a>
                                </p>
                            </div>
                        </div>
                    </main>
                </div>
            </Layout>
        );
    }
}

export default About;
