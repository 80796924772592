export default [
	{
		id: 1,
		message:
			"Florin does everything he can to get the job done and done right. This is the second time I've hired him, and I'll hire him again in the future.",
		client: 'J. Kent Pepper',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 2,
		message:
			'Great Freelancer in all ways. Quick turnaround and great communication. Very profesional freelancer.',
		client: 'Andreas',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 3,
		message:
			"Second time hiring him. Finished everything in a timely manner and his work is excellent. Can't recommend him enough.",
		client: 'Phoebe Kotliar',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 4,
		message:
			'Fast, reliable and easy to communicate with. We will be hiring for other jobs.',
		client: '248Shooter',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 5,
		message:
			'Extremely nice fellow! Great communication and quality of work. Recommend 100%.',
		client: 'Joni Salminen',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 6,
		message:
			"I had my concerns that due to a tight deadline this project can't be done. But Florin proved me wrong not only he delivered an outstanding work but he managed to deliver 1 day prior to the deadline. And when I asked for some revisions he made them in MINUTES. I'm looking forward to work with him again and I totally recommend him. Thanks again Florin!",
		client: 'Arthur Birnbaum',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 7,
		message:
			"I hired Florin Pop based on others positive experiences, and I understand why he's so highly rated. His code is very clean, he communicates well, and he likes to offer alternative solutions.",
		client: 'J. Kent Pepper',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 8,
		message:
			"Florin is a hard worker. Communication was also very good with him and he was very responsive all the time, something not easy to find in many freelancers. We'll definitely repeat with him.",
		client: 'Javier Ruiz',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 9,
		message:
			'Florin is an amazing frontend developer that delivered the task exactly how we need it, do your self a favor and hire him, you will not be disappointed by the work delivered. He will go the extra mile to make sure that you are happy with your project. I will surely work again with Florin!',
		client: 'Bogdan Sirbu',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 10,
		message:
			"Florin stepped up at short notice and delivered promptly without any fuss. I'm extremely grateful I found him and I hope to work with Florin again.",
		client: 'Arwenack Ltd.',
		platform: 'Upwork',
		link: 'https://upwork.com/fl/florinpop'
	},
	{
		id: 11,
		message:
			'Florin is a front-end development master, delivering pixel-perfect css and html designs. He is professional, highly available and delivers on his promises - an all around pleasure to work with!',
		client: 'Alexander De Ridder',
		platform: 'Linkedin',
		link: 'https://www.linkedin.com/in/florinpop17/'
	},
	{
		id: 12,
		message:
			"Alexandru Florin never fails to impress me by the quality of his work and delivering on time. When it comes to front-end, he is definitely my go to guy. Always is a pleasure to work with Alexandru even when deadlines are tight and pressure is great. It's reassuring to have a project in such good hands.",
		client: 'Louis P. Huard',
		platform: 'Linkedin',
		link: 'https://www.linkedin.com/in/florinpop17/'
	},
	{
		id: 13,
		message:
			'Florin has been of great help on our different web projects. He is very trustworthy and serious in the work done. Keep on the good work and energy, been a pleasure to collaborate.',
		client: 'Crisnen Aurmoogum',
		platform: 'Linkedin',
		link: 'https://www.linkedin.com/in/florinpop17/'
	},
	{
		id: 14,
		message:
			'Florin is a young and talented IT professional, proactive and responsible, with a strong work ethic. He is very strong in PSD2HTML conversions and HTML/CSS technology. He is a quick learner, eager to learn new technologies. He is focused and has the good dynamics to achieve due dates and outstanding results.',
		client: 'Florin Popescu',
		platform: 'Linkedin',
		link: 'https://www.linkedin.com/in/florinpop17/'
	},
	{
		id: 15,
		message:
			"I've worked with literally hundreds of HTML/CSS developers and I have to say the top spot goes to this guy. Florin is an amazing developer. He stresses on good, clean code and pays heed to the details. I love developers who respect each and every aspect of a throughly thought out design and do their best to put it in code. Florin goes over and beyond and transforms ART into PIXELS - without a glitch, every time.",
		client: 'Jahangir Khan',
		platform: 'Linkedin',
		link: 'https://www.linkedin.com/in/florinpop17/'
	},
	{
		id: 16,
		message:
			'Florin is a top notch designer and front end developer. He communicates well, works fast and produces quality work. We have been lucky to work with him!',
		client: 'Blake Charvoz',
		platform: 'Linkedin',
		link: 'https://www.linkedin.com/in/florinpop17/'
	}
];
